<div
  *ngIf="loading"
  class="bc-h-100 bc-container bc-flex bc-justify-content-center bc-align-items-center"
>
  <bc-loader sizeLoader="3xl"></bc-loader>
</div>
<!-- Sección tabla -->
<div style="margin: 10px;">
  <bc-table-container
    id="prac-rh-history"
    componentId="prac-rh-history"
    class="bc-w-100"
    [dataTable]="1"
  >
    <bc-table-header title="Parametrizar restricciones segmentos" class="bc-d-flex bc-ju">
      <div style="display: flex; justify-content: space-around; width: 100%">
        <div style="margin-top: 30px; width: 300px">
          <form [formGroup]="searchSubsegment">
            <bc-input-select
              [filter]="true"
              componentId="bc-select1"
              [formatType]="'simple'"
              [values]="subsegmentList"
              label="Filtrar segmentos"
              formControlName="selector"
              (valueOutput)="showSelectedValue($event)"
            ></bc-input-select>
          </form>
        </div>
        <div class="bc-align-self-center bc-mx-3" *ngIf="editPrivileges && !segmentFound">
          <button bc-button width="hug" bc-align-self-center class="height-50" (click)="openModal(searchSubsegment.get('selector')?.value)">
          <!-- *ngIf="editPrivileges && !clientFound && createClientMode" bc-button bc-align-self-center (click)="addClientRestrictions()" -->
              <span>Agregar</span>
              <bc-icon>plus</bc-icon>
            </button>
        </div>

        <div *ngIf="searchSubsegment.get('selector')?.value" class="bc-align-self-center bc-mx-3">
          <button bc-button bc-align-self-center (click)="clearClientSearch()" class="height-50">
            <span>Limpiar</span>
            <bc-icon>refresh</bc-icon>
          </button>
        </div>

        
      </div>
    </bc-table-header>
    <bc-table-content>
      <table
        bc-table
        [sort]="true"
        [pairPaginators]="true"
        [dropdownHtml]="true"
        [scroll]="false"
      >
        <caption></caption>
        <thead>
          <tr>
            <th scope="row" bc-cell>Sub-segmento</th>
            <th scope="row" bc-cell>Monto mínimo diario envío</th>
            <th scope="row" bc-cell>Monto mínimo diario recepción</th>
            <th scope="row" bc-cell>Monto máximo diario envío</th>
            <th scope="row" bc-cell>Monto máximo diario recepción</th>
            <th scope="row" bc-cell>Máximo de operaciones diarias</th>
            <th scope="row" bc-cell>¿Validar orden de pago?</th>
            <th scope="row" bc-cell>¿Validar saldo en cuenta?</th>
            <!-- <th scope="row" bc-cell>Estado</th> -->
            <th *ngIf="editPrivileges" scope="row" bc-cell>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let subsegment of subsegmentParams">
            <!-- <td bc-cell>{{ subsegment.segment }}</td> -->
            <td bc-cell>{{ subsegment.subsegment | titlecase }}</td>
            <td bc-cell>${{ subsegment.montoMinEnvio || 0 }}</td>
            <td bc-cell>${{ subsegment.montoMinRecepcion || 0 }}</td>
            <td bc-cell>${{ subsegment.montoMaxEnvio || 0 }}</td>
            <td bc-cell>${{ subsegment.montoMaxRecepcion || 0 }}</td>
            <td bc-cell>{{ subsegment.maximoOperaciones || 0 }}</td>
            <td bc-cell>
              <!-- {{ subsegment.ValidateOrderPayment }} -->
              <div style="display: flex; justify-content: center">
                <bc-icon
                  bc-icon
                  *ngIf="subsegment.ordenPago == true"
                  name="circle-check-filled"
                  class="icon-op"
                >
                </bc-icon>
                <bc-icon
                  bc-icon
                  *ngIf="subsegment.ordenPago == false"
                  name="circle-error"
                  class="icon-op"
                >
                </bc-icon>
              </div>
            </td>
            <td bc-cell>
              <!-- {{ subsegment.ValidateAccountBalance }} -->
              <div style="display: flex; justify-content: center">
                <bc-icon
                  bc-icon
                  *ngIf="subsegment.saldoCuenta == true"
                  name="circle-check-filled"
                  class="icon-op"
                >
                </bc-icon>
                <bc-icon
                  bc-icon
                  *ngIf="subsegment.saldoCuenta == false"
                  name="circle-error"
                  class="icon-op"
                >
                </bc-icon>
              </div>
            </td>
            <!-- <td bc-cell>{{ subsegment.status?.label }}</td> -->
            <td bc-cell *ngIf="editPrivileges">
              <!-- name = edit -->
              <div style="display: flex; justify-content: center">
                <i bc-icon class="icon-op" (click)="openModal(subsegment)">
                  <bc-icon bc-icon name="edit" class="icon-op"> </bc-icon>
                </i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </bc-table-content>
    <bc-table-footer>
      <!-- <bc-paginator
            type="full"
            [totalRegisters]="totalRecords.toString()"
            [perPage]="recordsPerPage.toString()"
            (onChangePage)="onChangePageTable($event)"
            perMultiple="5"
          ></bc-paginator> -->
    </bc-table-footer>
  </bc-table-container>
</div>
<div class="bc-flex">
  <bc-modal
    #segmentParam
    [title]="modalTittle"
    customWidth="large"
    titleAlign="center"
    [isCancellable]="true"
    buttonAlignment="horizontal"
    textBtn="Confirmar"
    textBtnLeft="Cancelar"
    typeNotify="success"
    (stateClick)="onModalPressButton($event)"
    [outerCloseTap]="true"
    class="bc-h-25"
  >
    <div modalContent>
      <div class="bc-container">
        <form [formGroup]="subsegmentsParamsForm" (ngSubmit)="onSubmit()">
          <div class="bc-row">
            <div class="bc-col-12">
              <bc-form-field disabled>
                <label>Sub-segmento:</label>
                <input bc-input type="text" formControlName="subsegment" />
              </bc-form-field>
            </div>
            <hr />
            <!-- <div class="bc-col-12 marg-top-20">
              
            </div> -->

            <div class="bc-col-12 text-center bc-mt-3 bc-mb-3">
              <div class="bc-col-6 text-center bc-mt-3 bc-mb-3">
                <bc-form-field disabled>
                  <bc-input-select
                    [values]="divisas"
                    status="error"
                    formControlName="currency"
                  ></bc-input-select>
                  <label>Moneda</label>
                </bc-form-field>
              </div>
              <div class="bc-col-6 text-center bc-mt-3 bc-mb-3">
                <bc-form-field>
                  <input
                    id="1"
                    bc-input
                    type="number"
                    [inputValue]="true"
                    value=""
                    formControlName="maximoOperaciones"
                  />
                  <label for="1">Máx operaciones diarias:</label>
                </bc-form-field>
              </div>
            </div>
            <div class="bc-col-6 text-center bc-mt-3 bc-mb-3">
              <bc-form-field>
                <em>currency-peso</em>
                <input
                  id="2"
                  bc-input
                  type="number"
                  [inputValue]="true"
                  value=""
                  formControlName="minimumSaleAmount"
                  required
                />
                <label for="2">Monto mínimo envío:</label>
                <span
                  *ngIf="subsegmentsParamsForm.get('minimumSaleAmount')?.errors?.['min']"
                >
                  El valor debe ser mayor o igual a
                  {{ paramLowMinimumSaleAmount }}
                </span>
                <span
                  *ngIf="subsegmentsParamsForm.get('minimumSaleAmount')?.errors?.['max']"
                >
                  El valor debe ser menor o igual a
                  {{ paramMaxMinimumSaleAmount }}
                </span>
                <span *ngIf="subsegmentsParamsForm.errors?.['minimumSaleAmountLessThanmaximumSaleAmount']">
                  Debe ser menor que el monto máximo de envío.
                </span>
              </bc-form-field>


              <bc-form-field>
                <em>currency-peso</em>
                <input
                  id="3"
                  bc-input
                  type="number"
                  [inputValue]="true"
                  value=""
                  formControlName="maximumSaleAmount"
                  required
                />
                <label for="3">Monto máximo envío:</label>
                <span
                  *ngIf="subsegmentsParamsForm.get('maximumSaleAmount')?.errors?.['min']"
                >
                  El valor debe ser mayor o igual a
                  {{ paramLowMaximumSaleAmount }}
                </span>
                <span
                  *ngIf="subsegmentsParamsForm.get('maximumSaleAmount')?.errors?.['max']"
                >
                  El valor debe ser menor o igual a
                  {{ paramMaxMaximumSaleAmount }}
                </span>
              </bc-form-field>
            </div>

            <div class="bc-col-6 text-center bc-mt-3 bc-mb-3">
              <bc-form-field>
                <em>currency-peso</em>
                <input
                  id="4"
                  bc-input
                  type="number"
                  [inputValue]="true"
                  value=""
                  formControlName="minimumPurchaseAmount"
                  required
                />
                <label for="4">Monto mínimo recepción:</label>
                <span
                  *ngIf="subsegmentsParamsForm.get('minimumPurchaseAmount')?.errors?.['min']"
                >
                  El valor debe ser mayor o igual a
                  {{ paramLowMinimumPurchaseAmount }}
                </span>
                <span
                  *ngIf="subsegmentsParamsForm.get('minimumPurchaseAmount')?.errors?.['max']"
                >
                  El valor debe ser menor o igual a
                  {{ paramMaxMinimumPurchaseAmount }}
                </span>
                <span *ngIf="subsegmentsParamsForm.errors?.['minimumPurchaseAmountLessThanmaximumPurchaseAmount']">
                  Debe ser menor que el monto máximo de recepción.
                </span>
              </bc-form-field>
              <bc-form-field>
                <em>currency-peso</em>
                <input
                  id="5"
                  bc-input
                  type="number"
                  [inputValue]="true"
                  value=""
                  formControlName="maximumPurchaseAmount"
                  required
                />
                <label for="5">Monto máximo recepción:</label>
                <span
                  *ngIf="subsegmentsParamsForm.get('maximumPurchaseAmount')?.errors?.['min']"
                >
                  El valor debe ser mayor o igual a
                  {{ paramLowMaximumPurchaseAmount }}
                </span>
                <span
                  *ngIf="subsegmentsParamsForm.get('maximumPurchaseAmount')?.errors?.['max']"
                >
                  El valor debe ser menor o igual a
                  {{ paramMaxMaximumPurchaseAmount }}
                </span>
              </bc-form-field>
            </div>
            <div class="bc-col-6 marg-top-20 text-center">
              <label>¿Validar orden de pago?</label>
              <bc-icon
                class="bc-icon bc-mx-2"
                size="2xs"
                bc-tooltip
                bcTooltipPosition="right"
                [bcTooltipText]="yesNoAccountBalanceText"
                >info-circle</bc-icon
              >
              <bc-input-select
                [values]="siNo"
                status="error"
                formControlName="validatePaymentOrder"
                [status]="validatePaymentOrderStatus"
              ></bc-input-select>
              <!-- (valueOutput)="showSelectedValue($event)" span="Validate" label="Título"-->
            </div>

            <div class="bc-col-6 marg-top-20 text-center">
              <label>¿Validar saldo en cuenta?</label>
              <bc-icon
                class="bc-icon bc-mx-2"
                size="2xs"
                bc-tooltip
                bcTooltipPosition="right"
                [bcTooltipText]="yesNoAccountBalanceText"
                >info-circle</bc-icon
              >
              <bc-input-select
                [values]="siNo"
                status="error"
                formControlName="validateAccountBalance"
                [status]="validateAccountBalanceStatus"
              ></bc-input-select>
              <!-- (valueOutput)="showSelectedValue($event)" span="Validate" label="Título"-->
            </div>

            <!-- <div  class="bc-col-6 marg-top-20 text-center">
              <label>Estado:</label>
              <bc-input-select
                [values]="habDesh"
                status="error"
                formControlName="status"
              ></bc-input-select>
            </div> -->
            <!-- (valueOutput)="showSelectedValue($event)" span="Validate" label="Título"-->
          </div>
        </form>
      </div>
    </div>
  </bc-modal>
</div>
