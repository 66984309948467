import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SistemaRoutingModule } from './sistema-routing.module';
import { EstadoComponent } from './estado/estado.component';
import {BcCardContentModule} from '@bancolombia/design-system-web/bc-card-content'
import { BcCardModule } from '@bancolombia/design-system-web/bc-card';
import {BcCardContainerModule} from '@bancolombia/design-system-web/bc-card-container'
import {BcIconModule} from '@bancolombia/design-system-web/bc-icon'
import { BcLoaderModule } from '@bancolombia/design-system-web/bc-loader';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';


@NgModule({
  declarations: [
    EstadoComponent
  ],
  imports: [
    BcCardContentModule,
    BcCardModule,
    BcCardContainerModule,
    BcIconModule,
    BcLoaderModule,
    BcButtonModule,

    CommonModule,
    SistemaRoutingModule
  ]
})
export class SistemaModule { }
