import { Component } from '@angular/core';
import { BcDataTableConfig } from '@bancolombia/design-system-behaviors';
import { BcDataTableThCell } from '@bancolombia/design-system-behaviors/lib/behaviors/organisms/bc-data-table/bc-data-table-config-interface';

@Component({
  selector: 'app-spread-tasas',
  templateUrl: './spread-tasas.component.html',
  styleUrls: ['./spread-tasas.component.scss']
})
export class SpreadTasasComponent {
  testDataFull = [
    [
      {
        type: 'id',
        value: 123123123,
      },
      {
        type: 'string',
        value: 'Plena',
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: 'fix',
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: '-',
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: 'Unidad',
        class: 'svn-bold-urgent',
      },
      {
        type: 'money',
        value: 15.00,
      },
      {
        type: 'money',
        value: 3985.00,
      },
      {
        type: 'string',
        value: '+',
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: 'Unidad',
        class: 'svn-bold-urgent',
      },
      {
        type: 'money',
        value: 20.00,
      },
      {
        type: 'money',
        value: 4020.00,
      },
    ],
    [
      {
        type: 'id',
        value: 123123123,
      },
      {
        type: 'string',
        value: 'Efectivo',
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: 'Tasa plena',
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: '-',
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: 'Porcentual',
        class: 'svn-bold-urgent',
      },
      {
        type: 'money',
        value: 0.50,
      },
      {
        type: 'money',
        value: 4004.93,
      },
      {
        type: 'string',
        value: '-',
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: 'Porcentual',
        class: 'svn-bold-urgent',
      },
      {
        type: 'money',
        value: 1.00,
      },
      {
        type: 'money',
        value: 3979.80,
      },
    ],
  ];
  
  testColumnHeaders: BcDataTableThCell[] = [
    {
      name: 'Tasa',
      dataType: 'string',
      tooltip: 'Tasa',
    },
    {
      name: 'Tasa Base',
      dataType: 'string',
      tooltip: 'Tipo de tasa',
    },
    {
      name: '+/-/% (Compra)',
      dataType: 'string',
    },
    {
      name: 'U*Spread Compra',
      dataType: 'string',
      tooltip: 'U* = Unidad',
      align: 'right',
    },
    {
      name: 'Spread Compra',
      dataType: 'string',
    },
    {
      name: 'Tasa Compra',
      dataType: 'label',
      tooltip: 'Tasa despues de calculo',
    },
    {
      name: '+/-/% (Venta)',
      dataType: 'string',
    },
    {
      name: 'U*Spread Venta',
      dataType: 'string',
      tooltip: 'U* = Unidad',
      align: 'right',
    },
    {
      name: 'Spread Venta',
      dataType: 'string',
    },
    {
      name: 'Tasa Venta',
      dataType: 'label',
      tooltip: 'Tasa despues de calculo',
    },
  ];
  
  dtConfigTableFull:BcDataTableConfig = {
    id: 'data-table-full',
    data: this.testDataFull,
    multicheck: false,
    columnHeaders: {
      data: this.testColumnHeaders,
      sort: true,
      separation: 'm',
    },
    actions: {
      tableAction: 'svnLoginActions',
    },
    header: {
      title: {
        text: 'Parametrizar Spread Tasas Efectivo',
        icon: 'money',
      }
    },
  };
}
