import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SistemaService } from '@app/core/services/sistema.service';
import {
  CardContentIconButton,
  CardContentStatus,
  CardContentText,
} from '@bancolombia/design-system-behaviors';
import {
  BcCardContentConfig,
  CardContentType,
} from '@bancolombia/design-system-web/bc-card-content';

@Component({
  selector: 'app-estado',
  templateUrl: './estado.component.html',
  styleUrls: ['./estado.component.scss'],
})
export class EstadoComponent implements OnInit {
  diaHabilBool: boolean= false;
  horarioHabilBool: boolean= false;
  ultimoEstadoBool: boolean= false;
  
  templateCards = {
    diaHabil: {
      headerIcon: 'refresh',
      headerText: 'Consultando día hábil',
      class: 'neutral',
      title: 'Cargando...',
      body: '',
      footerIcon: 'spinner',
      footerText: '',
    },
    horarioHabil: {
      headerIcon: 'refresh',
      headerText: 'Consultando horario hábil',
      class: 'neutral',
      title: 'Cargando...',
      body: '',
      footerIcon: 'spinner',
      footerText: '',
    },
    ultimoEstado: {
      headerIcon: 'refresh',
      headerText: 'Consultando último estado murex',
      class: 'neutral',
      title: 'Cargando...',
      body: '',
      footerIcon: 'spinner',
      footerText: '',
    },

  }

  diaHabil={
    headerIcon: 'refresh',
    headerText: 'Consultando día hábil',
    class: 'neutral',
    title: 'Cargando...',
    body: '',
    footerIcon: 'spinner',
    footerText: '',
  };
  
  horarioHabil={
    headerIcon: 'refresh',
    headerText: 'Consultando horario hábil',
    class: 'neutral',
    title: 'Cargando...',
    body: '',
    footerIcon: 'spinner',
    footerText: '',
  };

  ultimoEstado={
    headerIcon: 'refresh',
    headerText: 'Consultando último estado murex',
    class: 'neutral',
    title: 'Cargando...',
    body: '',
    footerIcon: 'spinner',
    footerText: '',
  };
  sistema={
    habilitado: {
      headerIcon: 'circle-check',
      headerText: 'HABILITADO',
      class: 'success',
      title: 'Sistema activo',
      body: 'El estado del sistema es activo',
      footerIcon: 'project',
      footerText: '',
    },
    deshabilitado: {
      headerIcon: 'circle-error',
      headerText: 'DESHABILITADO',
      class: 'failure',
      title: 'Sistema inactivo',
      body: 'El estado del sistema es inactivo',
      footerIcon: 'project',
      footerText: '',
    },
    cargando: {
      headerIcon: 'refresh',
      headerText: 'Consultando...',
      class: 'neutral',
      title: 'Esperando demás estados',
      body: '',
      footerIcon: 'spinner',
      footerText: '',
    }
  }
  estadoSistema= this.sistema.cargando;


  constructor(private sistemaService: SistemaService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.checkSystemStatus();
  }

  checkSystemStatus(): void{
    const date = new Date();
    this.sistemaService.postDiaHabil().subscribe({
      next: (data: any) => {
        if (data.diaHabil) {
          this.diaHabilBool = true;
          this.diaHabil={
            headerIcon: 'circle-check',
            headerText: 'HABILITADO',
            class: 'success',
            title: 'Día habil',
            body: 'Las operaciones están habilitadas para el día de hoy.',
            footerIcon: 'calendar-day',
            footerText: `Fecha consultada: ${date.toLocaleDateString()}`,
          };
          /* this.diaHabil = {
            idCard: 'card-1',
            cardPosition: 'vertical',
            cardType: CardContentType.cardIcon,
            cardSize: 'small',
            componentStatus: {
              type: 'icon-left',
              color: 'andino',
              border: 'center',
              icon: 'calendar-day',
              text: 'Habilitado',
            },
            icon: 'circle-check',
            title: {
              text: 'Día habil',
              classText: 'bc-text-title',
            },
            subtitle: {
              text: `El dia consultado es hábil`,
              classText: 'bc-text-subtitle',
            },
            text: {
              text: 'Las operaciones están habilitadas para el día de hoy.',
              classText: 'bc-text',
            },
            additionalText: {
              text: `Fecha consultada: ${date.toLocaleDateString()}`,
              classText: 'bc-text',
            },
            additionalIcon: 'alarm',
          }; */
        } else if (!data.diaHabil) {
          this.diaHabilBool = false;
          this.diaHabil={
            headerIcon: 'circle-error',
            headerText: 'DESHABILITADO',
            class: 'failure',
            title: 'Día no habil',
            body: 'No es posible operar el día de hoy.',
            footerIcon: 'calendar-day',
            footerText: `Fecha consultada: ${date.toLocaleDateString()}`,
          };
          /* this.diaHabil = {
            idCard: 'card-1',
            cardPosition: 'vertical',
            cardType: CardContentType.cardIcon,
            cardSize: 'small',
            componentStatus: {
              type: 'icon-left',
              color: 'alba',
              border: 'center',
              icon: 'calendar-day',
              text: 'Inhabilitado',
            },
            icon: 'circle-error',
            title: {
              text: 'Día no habil',
              classText: 'bc-text-title',
            },
            subtitle: {
              text: 'El dia consultado no es hábil',
              classText: 'bc-text-subtitle',
            },
            text: {
              text: 'No es posible operar el día de hoy.',
              classText: 'bc-text',
            },
            additionalText: {
              text: `Fecha consultada: ${date.toLocaleDateString()}`,
              classText: 'bc-text',
            },
            additionalIcon: 'alarm',
          }; */
        }
      },
      error: (error: any) => {
        this.diaHabilBool = false;
        this.diaHabil={
          headerIcon: 'user-error',
          headerText: 'SIN INFORMACIÓN',
          class: 'failure',
          title: 'Error consultando',
          body: 'No fue posible consultar el día hábil.',
          footerIcon: 'calendar-day',
          footerText: `Fecha consultada: ${date.toLocaleDateString()}`,
        };
        if (!this.diaHabilBool&& !this.horarioHabilBool&& !this.ultimoEstadoBool) {
          this.estadoSistema= this.sistema.deshabilitado;
          this.estadoSistema.footerText= `Estado consultado: ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}. \n ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`; 
        }

        /* this.diaHabil = {
          idCard: 'card-1',
          cardPosition: 'vertical',
          cardType: CardContentType.cardIcon,
          cardSize: 'small',
          componentStatus: {
            type: 'icon-left',
            color: 'neutro',
            border: 'center',
            icon: 'error',
            text: 'Error consultando',
          },
          icon: 'user-error',
          title: {
            text: 'Error',
            classText: 'bc-text-title',
          },
          subtitle: {
            text: 'No fue posible consultar',
            classText: 'bc-text-subtitle'
          },
          text: {
            text: 'No es posible validar el día hábil en este momento.',
            classText: 'bc-text',
          },
          additionalText: {
            text: `Fecha consultada: ${date.toLocaleDateString()}`,
            classText: 'bc-text',
          },
          additionalIcon: 'alarm',
        }; */

      },
      complete: () => {
        if (this.diaHabilBool&& this.horarioHabilBool&& this.ultimoEstadoBool) {
          this.estadoSistema= this.sistema.habilitado;
          this.estadoSistema.footerText= `Estado consultado: ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}. \n ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`; 
        }else {
          this.estadoSistema= this.sistema.deshabilitado;
          this.estadoSistema.footerText= `Estado consultado: ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}. \n ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`; 
        }
      }
    });

    this.sistemaService.postHorarioHabil().subscribe({
      next: (data: any) => {
        if (data.horarioHabil) {
          this.horarioHabilBool = true;
          /* this.horarioHabil = {
            idCard: 'card-1',
            cardPosition: 'vertical',
            cardType: CardContentType.cardIcon,
            cardSize: 'small',
            componentStatus: {
              type: 'icon-left',
              color: 'andino',
              border: 'center',
              icon: 'calendar-clock',
              text: 'Habilitado',
            },
            icon: 'circle-check',
            title: {
              text: 'Horario habil',
              classText: 'bc-text-title',
            },
            subtitle: {
              text: `El horario consultado es hábil`,
              classText: 'bc-text-subtitle',
            },
            text: {
              text: 'La hora consultada se encuentra habilitada para operar.',
              classText: 'bc-text',
            },
            additionalText: {
              text: `Hora consultada: ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
              classText: 'bc-text',
            },
            additionalIcon: 'alarm',
          }; */
          this.horarioHabil={
            headerIcon: 'circle-check',
            headerText: 'HABILITADO',
            class: 'success',
            title: 'Horario habil',
            body: 'La hora consultada se encuentra habilitada para operar.',
            footerIcon: 'alarm',
            footerText: `Hora consultada: ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
          };
        } else if (!data.horarioHabil) {
          this.horarioHabilBool = false;
          /* this.horarioHabil = {
            idCard: 'card-1',
            cardPosition: 'vertical',
            cardType: CardContentType.cardIcon,
            cardSize: 'small',
            componentStatus: {
              type: 'icon-left',
              color: 'alba',
              border: 'center',
              icon: 'calendar-day',
              text: 'Inhabilitado',
            },
            icon: 'circle-error',
            title: {
              text: 'Horario no habil',
              classText: 'bc-text-title',
            },
            subtitle: {
              text: `El horario no es hábil`,
              classText: 'bc-text-subtitle',
            },
            text: {
              text: 'La hora consultada no se encuentra habilitada para operar.',
              classText: 'bc-text',
            },
            additionalText: {
              text: `Hora consultada: ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
              classText: 'bc-text',
            },
            additionalIcon: 'alarm',
          }; */
          this.horarioHabil={
            headerIcon: 'circle-error',
            headerText: 'DESHABILITADO',
            class: 'failure',
            title: 'Horario no habil',
            body: 'La hora consultada no se encuentra habilitada para operar.',
            footerIcon: 'alarm',
            footerText: `Hora consultada: ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
          };
        }
      },
      error: (error: any) => {
        this.horarioHabilBool = false;
        /* this.horarioHabil = {
          idCard: 'card-1',
          cardPosition: 'vertical',
          cardType: CardContentType.cardIcon,
          cardSize: 'small',
          componentStatus: {
            type: 'icon-left',
            color: 'neutro',
            border: 'center',
            icon: 'error',
            text: 'Error consultando',
          },
          icon: 'user-error',
          title: {
            text: 'Error',
            classText: 'bc-text-title',
          },
          subtitle: {
            text: 'No fue posible consultar',
            classText: 'bc-text-subtitle'
          },
          text: {
            text: 'No es posible validar el horario hábil en este momento.',
            classText: 'bc-text',
          },
          additionalText: {
            text: `Hora consultada: ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
            classText: 'bc-text',
          },
          additionalIcon: 'alarm',
        }; */
        this.horarioHabil={
          headerIcon: 'user-error',
          headerText: 'SIN INFORMACIÓN',
          class: 'failure',
          title: 'Error consultando',
          body: 'No fue posible consultar el horario hábil.',
          footerIcon: 'alarm',
          footerText: `Hora consultada: ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
        };
        if (!this.diaHabilBool&& !this.horarioHabilBool&& !this.ultimoEstadoBool) {
          this.estadoSistema= this.sistema.deshabilitado;
          this.estadoSistema.footerText= `Estado consultado: ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}. \n ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`; 
        }
      },
      complete: () => {
        if (this.diaHabilBool&& this.horarioHabilBool&& this.ultimoEstadoBool) {
          this.estadoSistema= this.sistema.habilitado;
          this.estadoSistema.footerText= `Estado consultado: ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}. \n ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`; 
        }else {
          this.estadoSistema= this.sistema.deshabilitado;
          this.estadoSistema.footerText= `Estado consultado: ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}. \n ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`; 
        }
      }
    });
    
    this.sistemaService.postUltimoEstado().subscribe({
      next: (data: any) => {
        if (data.disponible) {
          this.ultimoEstadoBool = true;
          /* this.ultimoEstado = {
            idCard: 'card-1',
            cardPosition: 'vertical',
            cardType: CardContentType.cardIcon,
            cardSize: 'small',
            componentStatus: {
              type: 'icon-left',
              color: 'andino',
              border: 'center',
              icon: 'refresh',
              text: 'Habilitado',
            },
            icon: 'circle-check',
            title: {
              text: 'Último estado',
              classText: 'bc-text-title',
            },
            subtitle: {
              text: `El horario consultado es hábil`,
              classText: 'bc-text-subtitle',
            },
            text: {
              text: 'La hora consultada se encuentra habilitada para operar.',
              classText: 'bc-text',
            },
            additionalText: {
              text: `Hora consultada: ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
              classText: 'bc-text',
            },
            additionalIcon: 'alarm',
          }; */
          this.ultimoEstado={
            headerIcon: 'circle-check',
            headerText: 'HABILITADO',
            class: 'success',
            title: 'Último estado activo',
            body: 'El último estado consultado en murex es activo.',
            footerIcon: 'checks',
            footerText: `Fecha consultada: ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}. \n ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
          };
        } else if (!data.disponible) {
          this.ultimoEstadoBool = false;
          this.ultimoEstado={
            headerIcon: 'circle-error',
            headerText: 'DESHABILITADO',
            class: 'failure',
            title: 'Último estado inactivo',
            body: 'El último estado consultado en murex NO es activo.',
            footerIcon: 'checks',
            footerText: `Fecha consultada: ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}. \n ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
          };
        }
      },
      error: (error: any) => {
        this.ultimoEstadoBool = false;
        this.ultimoEstado={
          headerIcon: 'user-error',
          headerText: 'SIN INFORMACIÓN',
          class: 'failure',
          title: 'Error consultando',
          body: 'No fue posible consultar el último estado en Murex.',
          footerIcon: 'checks',
          footerText: `Fecha consultada: ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}. \n ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
        };
        if (!this.diaHabilBool&& !this.horarioHabilBool&& !this.ultimoEstadoBool) {
          this.estadoSistema= this.sistema.deshabilitado;
          this.estadoSistema.footerText= `Estado consultado: ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}. \n ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`; 
        }
      },
      complete: () => {
        if (this.diaHabilBool&& this.horarioHabilBool&& this.ultimoEstadoBool) {
          this.estadoSistema= this.sistema.habilitado;
          this.estadoSistema.footerText= `Estado consultado: ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}. \n ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`; 
        }else {
          this.estadoSistema= this.sistema.deshabilitado;
          this.estadoSistema.footerText= `Estado consultado: ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}. \n ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`; 
        }
      }
    });
  }

  refreshStates(): void {
    this.diaHabilBool= false;
    this.diaHabil = this.templateCards.diaHabil;
    this.horarioHabilBool= false;
    this.horarioHabil = this.templateCards.horarioHabil;
    this.ultimoEstadoBool= false;
    this.ultimoEstado = this.templateCards.ultimoEstado;
    this.estadoSistema= this.sistema.cargando;
    this.checkSystemStatus();
  }
}
