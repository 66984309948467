import { Component, OnInit } from '@angular/core';
import { IBlockedClients } from '../../../../src/app/core/models/blocked-clients';
import { ClientMptApiService } from '../../../../src/app/core/services/client-mpt-api.service';
import { RolService } from '../../core/services/rol.service';
import { CLIENTES_BLOQUEADOS_ROUTE } from '../../core/constants/routes';
import { ISelectValues } from 'src/app/core/models/informacion-parametrica';

@Component({
  selector: 'app-clientes-bloqueados',
  templateUrl: './clientes-bloqueados.component.html',
  styleUrls: ['./clientes-bloqueados.component.scss']
})
export class ClientesBloqueadosComponent implements OnInit{
  docTypes: ISelectValues[]
  /* ;
  applicableProducts: ISelectValues[] */= [
    { label: 'CC', value: 1 },
    { label: 'NIT', value: 2 }
  ];
  blockedClientsList!: IBlockedClients[];
  auxBlockedClientsList!: IBlockedClients[];
  clientFound: boolean = false;
  mptClients: string = "mptClients";
  allowContent: boolean = false;
  canParametrize: boolean = false;
  loaded: boolean = false;

  constructor(
    private clientMptService: ClientMptApiService,
    private roleService: RolService
  ) { }

  ngOnInit(): void {
      this.clientMptService.getRetrieveBlockedClientsInformation().subscribe({
        next: (data: any[]) =>{
          this.blockedClientsList = data.slice(0, 10);
          this.auxBlockedClientsList = this.blockedClientsList;
        },
        error(err) {
          console.log(err);
        },
        complete: () =>{
          this.loaded=true;
        },
      });
      this.canParametrize = this.roleService.enablePrivileges(CLIENTES_BLOQUEADOS_ROUTE);
      /* this.roleService.enablePrivileges(); */
  }


  onChangePageNumeric($event: any){
  }

  updateClientFound(client: any){
    this.blockedClientsList = [];
    this.blockedClientsList.push(client);
    this.clientFound = true;
  }	

  clearClientSearch(){
    this.blockedClientsList = this.auxBlockedClientsList;
    this.clientFound = false;
  }

}
