
<div >
  <div class="bc-flex bc-justify-content-center bc-margin-bottom-5 bc-margin-top-5">
    <h1>Estado del sistema</h1>
  </div>
  <button bc-button class="float-button-down" (click)="refreshStates()">
    <bc-icon>refresh</bc-icon>
    <span>Actualizar estado</span>
  </button>
  <div class="bc-d-grid" style="display: flex; justify-content: space-around;">
    <!-- <bc-card-content *ngIf="diaHabil" [dataConfig]="diaHabil" altImgIllustrator="imagen test 1">
    </bc-card-content> -->
    <div class="bc-margin-2">
      <bc-card-container cardType="default" borderClass="bc-border-radius-4-full" shadowClass="bc-shadow-05">
        <div class="card">
          <div class="card-header" [ngClass]="diaHabil.class">
            <div class="icon">
              <bc-icon size="md" name='{{diaHabil.headerIcon}}' [ariaHidden]="false" ariaLabel="Icono habilitado"></bc-icon>
            </div>
            <h5 class="status">{{diaHabil.headerText}}</h5>
          </div>
        
          <div class="card-body">
            <h2>{{diaHabil.title}}</h2>
            <p class="subtext">{{diaHabil.body}}</p>
        
            <div class="time-info">
              <bc-loader *ngIf="diaHabil.footerIcon=='spinner'" sizeLoader="3xl"></bc-loader>

              <bc-icon *ngIf="diaHabil.footerIcon!='spinner'" size="lg" name='{{diaHabil.footerIcon}}' [ariaHidden]="false" ariaLabel="Icono de hora"></bc-icon>

              <span>{{diaHabil.footerText}}</span>
            </div>
          </div>
        </div> 
  
      </bc-card-container>
    </div>
  
    <div class="bc-margin-2">
      <bc-card-container cardType="default" borderClass="bc-border-radius-4-full" shadowClass="bc-shadow-05">
        <div class="card">
          <div class="card-header" [ngClass]="horarioHabil.class">
            <div class="icon">
              <bc-icon size="md" name='{{horarioHabil.headerIcon}}' [ariaHidden]="false" ariaLabel="Icono habilitado"></bc-icon>
            </div>
            <h5 class="status">{{horarioHabil.headerText}}</h5>
          </div>
        
          <div class="card-body">
            <h2>{{horarioHabil.title}}</h2>
            <p class="subtext">{{horarioHabil.body}}</p>
        
            <div class="time-info">
              <bc-loader *ngIf="diaHabil.footerIcon=='spinner'" sizeLoader="3xl"></bc-loader>
              <bc-icon *ngIf="diaHabil.footerIcon!='spinner'" size="lg" name='{{horarioHabil.footerIcon}}' [ariaHidden]="false" ariaLabel="Icono de hora"></bc-icon>
              <span>{{horarioHabil.footerText}}</span>
            </div>
          </div>
        </div> 
  
      </bc-card-container>
    </div>
  
    <div class="bc-margin-2">
      <bc-card-container cardType="default" borderClass="bc-border-radius-4-full" shadowClass="bc-shadow-05">
        <div class="card">
          <div class="card-header" [ngClass]="ultimoEstado.class">
            <div class="icon">
              <bc-icon size="md" name='{{ultimoEstado.headerIcon}}' [ariaHidden]="false" ariaLabel="Icono habilitado"></bc-icon>
            </div>
            <h5 class="status">{{ultimoEstado.headerText}}</h5>
          </div>
        
          <div class="card-body">
            <h2>{{ultimoEstado.title}}</h2>
            <p class="subtext">{{ultimoEstado.body}}</p>
        
            <div class="time-info">
              <bc-loader *ngIf="diaHabil.footerIcon=='spinner'" sizeLoader="3xl"></bc-loader>
              <bc-icon *ngIf="diaHabil.footerIcon!='spinner'" size="lg" name='{{ultimoEstado.footerIcon}}' [ariaHidden]="false" ariaLabel="Icono de hora"></bc-icon>
              <span>{{ultimoEstado.footerText}}</span>
            </div>
          </div>
        </div> 
  
      </bc-card-container>
    </div>
    <!-- <bc-card-content *ngIf="horarioHabil" [dataConfig]="horarioHabil" altImgIllustrator="imagen test 1">
    </bc-card-content>
  
    <bc-card-content *ngIf="ultimoEstado" [dataConfig]="ultimoEstado" altImgIllustrator="imagen test 1"  >
    </bc-card-content> -->
  </div>
  <br><br>
  <div class="bc-d-flex bc-justify-content-center">
    <div class="card">
      <div class="card-header" [ngClass]="ultimoEstado.class">
        <div class="icon">
          <bc-icon size="md" name={{estadoSistema.headerIcon}} [ariaHidden]="false" ariaLabel="Icono habilitado"></bc-icon>
        </div>
        <h5 class="status">{{estadoSistema.headerText}}</h5>
      </div>
    
      <div class="card-body">
        <h2>{{estadoSistema.title}}</h2>
        <p class="subtext">{{estadoSistema.body}}</p>
    
        <div class="time-info">
          <bc-loader *ngIf="estadoSistema.footerIcon=='spinner'" sizeLoader="3xl"></bc-loader>
          <bc-icon *ngIf="estadoSistema.footerIcon!='spinner'" size="lg" name={{estadoSistema.footerIcon}} [ariaHidden]="false" ariaLabel="Icono de hora"></bc-icon>
          <span class="bc-m-2">{{estadoSistema.footerText}}</span>
        </div>
      </div>
    </div>
   <!--  <bc-card-container cardType="default" borderClass="bc-border-radius-4-full" shadowClass="bc-shadow-05">
  
    </bc-card-container> -->
  </div>
  <br><br>
</div>

