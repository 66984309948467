import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BcCircleLoadingComponent } from '@bancolombia/design-system-web/bc-circle-loading';
import { Subject } from 'rxjs';
import { ClientMptApiService } from '../../../app/core/services/client-mpt-api.service';
import { ParameterizedClientSummary } from '../../../app/core/models/client-mpt';
import { DocumentType } from '../../core/models/mctDataTypes';
import { ClientMCTApiService } from '@app/core/services/client-mct-api.service';
import { DataSelect } from '@bancolombia/design-system-behaviors/lib/behaviors/organisms/bc-search/bc-search-interfaces';

@Component({
  selector: 'app-buscar-cliente',
  templateUrl: './buscar-cliente.component.html',
  styleUrls: ['./buscar-cliente.component.scss']
})
export class BuscarClienteComponent implements OnInit{

  @Output() client = new EventEmitter<ParameterizedClientSummary>();
  @Input() clientSource!: string;
  public disabledDocumentType: boolean = false;

  public searchClientForm!: FormGroup;
  documentTypes: any[] = [
    { label: 'CC', value: 1 },
    { label: 'NIT', value: 2 }
  ];
  public list: any[] = [];
  public errorResponse: any = {} as any;
  public documentTypeLabel: any;

  public selectClass = 'bc-input';
  public inputClass = 'bc-input';
  public now!: string;
  public dataRiskProfile!: any;
  public profilesToValidate = {} as any[];
  public fieldValid: boolean = true;
  public fieldErrorMsg: string = '';
  public insertBr: boolean = false;

  @ViewChild(BcCircleLoadingComponent, { static: false })
  bcCircleLoading!: BcCircleLoadingComponent;
  circleLoadingText = 'Buscando cliente...';
  circleLoadingAriaLabel = '';
  loaderSpeed = '0.5';
  setDataSelect: DataSelect = {
    selectInput: {
      nameSearch: 'cuenta',
      items: [],
    },
  };
  private readonly _destroying$ = new Subject<void>();
  
  constructor(
    private fb: FormBuilder,
    private clientMptApiService: ClientMptApiService,
    private clientMctApiService: ClientMCTApiService,
  ) {
    this.searchClientForm = this.fb.group({
      documentType: ['',[Validators.required]],
      documentNumber: ['', [Validators.required, Validators.min(9999999), Validators.max(9999999999999)]],
    });
  }
  ngOnInit(): void {
    this.clientMctApiService.getDocTypeList().subscribe({
      next: (data: DocumentType[]) => {
        this.documentTypes = data;
        
        this.documentTypes =this.documentTypes.map((item) => ({
          label: item.descMx,
          value: item.tipDocMdm,
        }));
        this.setDataSelect = {
          selectInput: {
            nameSearch: 'documentType',
            items: this.documentTypes,
          }
        };
      },
      error: (err) => {
        console.log(err);
      },
    });
    
  }

  clearSearchForm(): void {
    // Temporarily disable validators
    Object.keys(this.searchClientForm.controls).forEach(controlName => {
      const control = this.searchClientForm.controls[controlName];
      control.clearValidators();
      control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });

    // Reset the form
    this.searchClientForm.reset();

    // Re-enable validators
    Object.keys(this.searchClientForm.controls).forEach(controlName => {
      const control = this.searchClientForm.controls[controlName];
      control.setValidators(this.getValidatorsForControl(controlName));
      control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      control.markAsPristine();
      control.markAsUntouched();
    });
  }

  private getValidatorsForControl(controlName: string) {
    switch (controlName) {
      case 'documentType':
        return [Validators.required];
      case 'documentNumber':
        return [Validators.required];
      // Add other controls and their validators as needed
      default:
        return [];
    }
  }

  search():void {
    if (this.searchClientForm.valid) {
      switch (this.clientSource) {
        case "mptClients":
          this.clientMptApiService.getClientByDocumentTypeAndDocumentNumber(
            this.searchClientForm.controls['documentType'].value,
            this.searchClientForm.controls['documentNumber'].value
          ).subscribe({
            next: (data: ParameterizedClientSummary) => {             
              if (!data.id) {
                data.cliente.number = this.searchClientForm.controls['documentNumber'].value;
                data.cliente.type = this.searchClientForm.controls['documentType'].value;
                this.client.emit(data);
              }else{
                this.client.emit(data);
              }
              /* this.searchClientForm.reset(); */
            },
            error: (err) => {
              this.client.emit({
                cliente:{
                  idCliente: 0,
                  number: this.searchClientForm.controls['documentNumber'].value,
                  type: this.searchClientForm.controls['documentType'].value,
                  name: ''
                },
                subsegment: '',
                moneda: 0,
                montoMinEnvio: '',
                montoMinRecepcion: '',
                montoMaxEnvio: '',
                montoMaxRecepcion: '',
                ordenPago: false,
                saldoCuenta: false,
                maximoOperaciones: null,
                status: false,
              });
              console.log(err);
            },
          });
          break;
  
      
        default:
          break;
      }
    }
    
  }

}
