export const environment = {
  production: false,
  currentEnv: 'dev',
  mptBaseUrl: 'https://negocios-inversion-int-dev.apps.ambientesbc.com/',
  ipServiceUrl: 'https://api.ipify.org/?format=json',
  azureConfig: {
    redirectUri:'https://mpt-dev.apps.ambientesbc.com',//lo redirige aqui luego de autenticarse /auth
    tenantId: '428f4e2e-13bf-4884-b364-02ef9af41a1d',
    clientId: 'abb1a8b9-651f-4b48-85bb-950aba605797',
    scope:'api://abb1a8b9-651f-4b48-85bb-950aba605797/default',
 
    graph: 'https://graph.microsoft.com',
    loginMicrosoft: 'https://login.microsoftonline.com',
  },
  releaseInfo: {
    version: '',
    artifact: 'feature/example_branch_name',
  },
};
