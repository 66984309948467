import { AbstractControl, ValidationErrors, ValidatorFn, FormGroup } from '@angular/forms';


export function minLessThanMaxValidator(minControlName: string, maxControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const minControl = formGroup.get(minControlName);
    const maxControl = formGroup.get(maxControlName);

    if (!minControl || !maxControl) {
      return null;
    }

    const minValue = minControl.value;
    const maxValue = maxControl.value;

    if (minValue !== null && maxValue !== null && minValue >= maxValue) {
      const error = { [`${minControlName}LessThan${maxControlName}`]: true };
      minControl.setErrors(error);
      return error;
    }

    // Clear previous errors if validation passes
    if (minControl.hasError(`${minControlName}LessThan${maxControlName}`)) {
      minControl.setErrors(null);
    }

    return null;
  };
}