import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { rolesConfig } from '@app/core/guards/config/roles.config';
import { AuthService } from '@app/core/services/auth.service';
import { RolService } from '@app/core/services/rol.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.css']
})
export class MenuHeaderComponent implements OnInit {
  name = "";
  isIframe = false;
  loginDisplay = false;
  clientsCateg = false;
  opsCateg = false;
  decodedToken: any;
  user:any;
  public menuClientes: any[] = [];
  public menuOperaciones: any[] = [];

  logged: boolean=false;
  menuHeader='';
  menu: any[] = [];
  constructor(
    private authService: MsalService,
    private localAuthService: AuthService,
    private rolService: RolService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.localAuthService.checkoutAccount().subscribe({
      next: (usr: any) => {
        this.logged = usr.logged;
        this.user = usr;
        this.name = this.user.profile.name;
      },
      error: (error: any) => console.log(error),
    });
    this.buildMenuFromRoles();
  }

  buildMenuFromRoles() {
    this.rolService.getAllRoles().subscribe({
      next: (user) => {
        rolesConfig.routes.forEach((routeConfig) =>{
            let tempParentPath = routeConfig.parentPath;
            let tempParentName = routeConfig.parentName;
            let tempMenu:any[] = [];
            routeConfig.children.forEach((child) => {
              if(!user?.profile.roles)
                this.router.navigate(['/unauthorized']);
              if(child.roles.some((role) => user?.profile.roles.includes(role))){
                tempMenu.push({
                  path: tempParentPath + child.path, 
                  name: child.name,
                  parentPath: tempParentPath, 
                  parentName: tempParentName});
              }
            });
            if (tempMenu.length > 0) {
              this.menu.push({path: tempParentPath, 
                icon: routeConfig.icon,
                name: tempParentName, 
                children: tempMenu});
            }
          }          
        );       
      },
      error(err) {
        console.log("Couldn't catch user roles: ", err);
      },
    });
  }


  login() {
    this.authService.loginPopup().subscribe({
      next: (result: any) => {
        this.setLoginDisplay();
      },
      error: (error: any) => console.log(error),
    });
  }

  logout() {
    this.localAuthService.logOut();
  }
  showRoles(){
    this.localAuthService.checkoutRoles(); 
  }
  
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  navigateToUrl(url: string) {
    this.router.navigate([url]);
  }
}