import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vista-crear-cliente-mx',
  templateUrl: './vista-crear-cliente-mx.component.html',
  styleUrls: ['./vista-crear-cliente-mx.component.scss']
})
export class VistaCrearClienteMxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
