import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpreadTasasComponent } from './spread-tasas/spread-tasas.component';
import { TasasRoutingModule } from './tasas-routing.module';
import { BcCardModule } from '@bancolombia/design-system-web/bc-card';
import {BcDataTableModule} from '@bancolombia/design-system-web/bc-data-table'
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    SpreadTasasComponent
  ],
  imports: [
    BcCardModule,
    BcDataTableModule,

    ReactiveFormsModule,
    CommonModule,
    TasasRoutingModule
  ]
})
export class TasasModule { }
