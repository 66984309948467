import { CLIENTES_BLOQUEADOS_ROUTE, CLIENTES_ROUTE, SEGMENTOS_ROUTE, LISTA_OPERACIONES_ROUTE, LISTA_PARAMETROS_SEGMENTOS, OPERACIONES_ROUTE, PARAMETRIZAR_CLIENTE_ROUTE, SISTEMA_ROUTE, ESTADO, TASAS_ROUTE, SPREAD_TASA_EFECTIVO } from "@app/core/constants/routes";


export const rolesConfig = {
    routes: [
      {
        parentPath: CLIENTES_ROUTE,
        parentName: 'Clientes',
        icon: 'line-of-people',
        children:[
          {
            path: PARAMETRIZAR_CLIENTE_ROUTE,
            name: 'Parametrizar cliente',
            roles: [
              'TES_SVN_CONSUL',
              'TES_SVN_TRADER',
              'MPT_SVN_JEFE',
              'MPT_SVN_PARAM',
              'MPT_SVN_TRADERPARAM',
              'MPT_SVN_TI',
            ],
          },
          /* {
            path: CLIENTES_BLOQUEADOS_ROUTE,
            name: 'Clientes bloqueados',
            roles: [
              'MPT_SVN_CONSUL',
              'MPT_SVN_JEFE',
              'MPT_SVN_PARAM',
              'MPT_SVN_TRADER',
              'MPT_SVN_TRADERPARAM',
              'MPT_SVN_TI',            
              'MCT_ADMIN'
            ],
          }, */
        ]
      },
      /* {
        parentPath: OPERACIONES_ROUTE,
        parentName: 'Operaciones',
        icon: 'send-money',
        children: [
          {
            path: LISTA_OPERACIONES_ROUTE,
            name: 'Lista de operaciones',
            roles: [
              'TES_SVN_CONSUL',
              'TES_SVN_TRADER',
              'MPT_SVN_JEFE',
              'MPT_SVN_PARAM',
              'MPT_SVN_TRADERPARAM',
              'MPT_SVN_TI',
            ],
          },
        ]
      }, */
      {
        parentPath: SEGMENTOS_ROUTE,
        parentName: 'Segmentos',
        icon: 'foundation',
        children: [
          {
            path: LISTA_PARAMETROS_SEGMENTOS,
            name: 'Lista de segmentos',
            roles: [
              'TES_SVN_CONSUL',
              'TES_SVN_TRADER',
              'MPT_SVN_JEFE',
              'MPT_SVN_PARAM',
              'MPT_SVN_TRADERPARAM',
              'MPT_SVN_TI',
            ],
          },
        ]
      },
      {
        parentPath: SISTEMA_ROUTE,
        parentName: 'Sistema',
        icon: 'table-settings',
        children: [
          {
            path: ESTADO,
            name: 'Estado del sistema',
            roles: [
              'TES_SVN_CONSUL',
              'TES_SVN_TRADER',
              'MPT_SVN_JEFE',
              'MPT_SVN_PARAM',
              'MPT_SVN_TRADERPARAM',
              'MPT_SVN_TI',
              'MCT_ADMIN'
            ],
          },
        ]
      },
      {
        parentPath: TASAS_ROUTE,
        parentName: 'Tasas',
        icon: /* 'day-to-day', */
        /* 'lend', */
        /* 'finantial-growth-indicator', */
        /* 'holding-money', */
        'money-exchange',
        /* 'percentage', */
        /* 'tag-price', */
        /* 'file-coin', */
        /* 'files-money', */
        /* 'redo-money', */

        children: [
          {
            path: SPREAD_TASA_EFECTIVO,
            name: 'Spreads Tasa Efectivo',
            roles: [
              'TES_SVN_CONSUL',
              'TES_SVN_TRADER',
              'MPT_SVN_JEFE',
              'MPT_SVN_PARAM',
              'MPT_SVN_TRADERPARAM',
              'MPT_SVN_TI',
              'MCT_ADMIN'
            ],
          },
        ]
      },
    ],
  };

export const rolePrivileges = {
  routes: [
    {
      path: PARAMETRIZAR_CLIENTE_ROUTE,
      parentPath: CLIENTES_ROUTE,
      parentName: 'Clientes',
      name: 'Parametrizar cliente',
      roles: [
        'TES_SVN_TRADER',
        'MPT_SVN_PARAM',
        'MPT_SVN_TRADERPARAM',
      ],
    },
    {
      path: LISTA_PARAMETROS_SEGMENTOS,
      parentPath: SEGMENTOS_ROUTE,
      parentName: 'Segmentos',
      name: 'Parametrizar restricciones segmentos',
      roles: [
        'MPT_SVN_PARAM',
        'MPT_SVN_TRADERPARAM',
      ],
    },
    
    /* {
      path: CLIENTES_BLOQUEADOS_ROUTE,
      parentPath: CLIENTES_ROUTE,
      parentName: 'Clientes',
      name: 'Clientes bloqueados',
      roles: [
        'MPT_SVN_CONSUL',
        'MPT_SVN_JEFE',
        'MPT_SVN_PARAM',
        'MPT_SVN_TRADER',
        'MPT_SVN_TRADERPARAM',
        'MPT_SVN_TI',            
        'MCT_ADMIN'
      ],
    }, */
  ],
};
  
  
