<!-- Sección loader -->
<div *ngIf="loading"  class="bc-h-100 bc-container bc-flex bc-justify-content-center bc-align-items-center">
  <bc-loader sizeLoader="3xl"></bc-loader>
</div>
<br>

<!-- Sección buscar cliente y opciones especificas de la página -->
<div class="bc-flex bc-justify-content-center">
  <h2>Buscar cliente</h2>
</div>
<div class="bc-container">
  <div class="bc-flex bc-justify-content-center">
    <div class="bc-p-2 bc-w-70">
        <app-buscar-cliente [clientSource]="mptClients" (client)="updateClientFound($event)"></app-buscar-cliente>
    </div>
    <div class="bc-align-self-center bc-mx-3">
      <button *ngIf="editPrivileges && !clientFound && createClientMode" bc-button bc-align-self-center (click)="addClientRestrictions()">
          <span>Agregar</span>
          <bc-icon>plus</bc-icon>
        </button>
    </div>
    <div class="bc-align-self-center bc-mx-3">
        <button *ngIf="clientFound || createClientMode" bc-button bc-align-self-center (click)="clearClientSearch()">
            <span>Limpiar búsqueda</span>
            <bc-icon>refresh</bc-icon>
        </button>
    </div>
    
  </div>
</div>

<!-- Sección tabla -->
<div style="margin: 10px">
  <bc-table-container
    id="prac-rh-history"
    componentId="prac-rh-history"
    class="bc-w-100"
    [dataTable]="1"
  >
    <bc-table-header title="Parametrizar clientes MPT"> </bc-table-header>
    <bc-table-content>
      <table
        bc-table
        [sort]="true"
        [pairPaginators]="true"
        [dropdownHtml]="true"
        [scroll]="false"
      >
        <caption></caption>
        <thead>
          <tr>
            <th scope="row" bc-cell>Nit</th>
            <th scope="row" bc-cell>Nombre</th>
            <th scope="row" bc-cell>Monto mínimo diario envío</th>
            <th scope="row" bc-cell>Monto mínimo diario recepción</th>
            <th scope="row" bc-cell>Monto máximo diario envío</th>
            <th scope="row" bc-cell>Monto máximo diario recepción</th>
            <th scope="row" bc-cell>¿Validar orden de pago?</th>
            <th scope="row" bc-cell>¿Validar saldo en cuenta?</th>
            <th *ngIf="false" scope="row" bc-cell>Estado</th>
            <th *ngIf="editPrivileges" scope="row" bc-cell>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let client of clientList">
            <td bc-cell>{{ client.cliente?.number }}</td>
            <td bc-cell>{{ client.cliente?.name }}</td>
            <td bc-cell>${{ client.montoMinEnvio }}</td>
            <td bc-cell>${{ client.montoMinRecepcion }}</td>
            <td bc-cell>${{ client.montoMaxEnvio }}</td>
            <td bc-cell>${{ client.montoMaxRecepcion }}</td>
            <td bc-cell>
              <div style="display: flex; justify-content: center">
                <bc-icon
                  bc-icon
                  *ngIf="client.ordenPago"
                  name="circle-check-filled"
                  class="icon-op"
                >
                </bc-icon>
                <bc-icon
                  bc-icon
                  *ngIf="!client.ordenPago"
                  name="circle-error"
                  class="icon-op"
                >
                </bc-icon>
              </div>
            </td>
            <td bc-cell>
              <div style="display: flex; justify-content: center">
                <bc-icon
                  bc-icon
                  *ngIf="client.saldoCuenta"
                  name="circle-check-filled"
                  class="icon-op"
                >
                </bc-icon>
                <bc-icon
                  bc-icon
                  *ngIf="!client.saldoCuenta"
                  name="circle-error"
                  class="icon-op"
                >
                </bc-icon>
              </div>
            </td>
            <td *ngIf="false" bc-cell>
              <div style="display: flex; justify-content: center">
                <bc-icon
                  bc-icon
                  *ngIf="client.status"
                  name="circle-check-filled"
                  class="icon-op"
                >
                </bc-icon>
                <bc-icon
                  bc-icon
                  *ngIf="!client.status"
                  name="circle-error"
                  class="icon-op"
                >
                </bc-icon>
              </div>
            </td>
            <td *ngIf="editPrivileges" class="bc-flex bc-mt-3" bc-cell>
              <!-- name = edit -->
              <i bc-icon class="icon-op" (click)="openModal(client)">
                <bc-icon bc-icon name="edit" class="icon-op"> </bc-icon>
              </i>
              <i bc-icon class="icon-op" (click)="openModal(client)">
                <bc-icon bc-icon name="trash" class="icon-op"> </bc-icon>
              </i>
            </td>
          </tr>
          
        </tbody>
        
      </table>
    </bc-table-content>
    <bc-table-footer>
      <!-- <bc-paginator
            type="full"
            [totalRegisters]="totalRecords.toString()"
            [perPage]="recordsPerPage.toString()"
            (onChangePage)="onChangePageTable($event)"
            perMultiple="5"
          ></bc-paginator> -->
    </bc-table-footer>
  </bc-table-container>
</div>

<!-- sección modal -->


<bc-modal
  #customerParam
  [title]="modalTittle"
  customWidth="large"
  titleAlign="center"
  [isCancellable]="true"
  buttonAlignment="horizontal"
  textBtn="Confirmar"
  textBtnLeft="Cancelar"
  typeNotify="success"
  (stateClick)="onModalPressButton($event)"
  [outerCloseTap]="true"
  class="bc-h-25"
>
  <div modalContent>
    <div class="bc-container">
      <form [formGroup]="currencyForm" (ngSubmit)="onSubmit()">
        <div class="bc-row">
          <div *ngIf="createClientMode" class="bc-col-6 text-center bc-mt-3 bc-mb-3">
            <bc-form-field disabled>
              <em>id</em>
              <input 
                bc-input
                autocomplete="off"  
                type="text" 
                formControlName="documentType"  
                required
                class="bc-input" 
                aria-label="Tipo de Documento"
                [inputValidation]=true
              >
              <label>Tipo de Documento</label>
            </bc-form-field>
          </div>
          <div *ngIf="createClientMode" class="bc-col-6 text-center bc-mt-3 bc-mb-3">
            <bc-form-field disabled>
              <em>id-number</em>
              <input 
                bc-input
                autocomplete="off"  
                type="number" 
                formControlName="documentNumber"
                required 
                class="bc-input" 
                aria-label="Número Documento"
                [inputValidation]=true
              >
              <label>Número Documento</label>
            </bc-form-field>
          </div>
          <div class="bc-col-12">
            <bc-form-field disabled>
              <label>Empresa:</label>
              <input bc-input type="text" formControlName="enterpriseName">
            </bc-form-field>
          </div>
          <hr>
          <div class="bc-col-12 text-center bc-mt-3 bc-mb-3">
            <div class="bc-col-6 text-center bc-mt-3 bc-mb-3">
              <bc-form-field disabled>
                <bc-input-select
                  [values]="divisas"
                  status="error"
                  formControlName="currency"
                ></bc-input-select>
                <label>Moneda</label>
              </bc-form-field>
            </div>
            <div class="bc-col-6 text-center bc-mt-3 bc-mb-3">
              <bc-form-field>
                <input
                  id="1"
                  bc-input
                  type="number"
                  [inputValue]="true"
                  value=""
                  formControlName="maximoOperaciones"
                />
                <label for="1">Máx operaciones diarias:</label>
              </bc-form-field>
            </div>
          </div>


          <div class="bc-col-6 text-center bc-mt-3 bc-mb-3">
            <bc-form-field>
              <em>currency-peso</em>
              <input id="4" bc-input  type="number" [inputValue]="true" value="" formControlName="minimumSaleAmount" required>
              <label for="4">Monto mínimo envío:</label>
              <span *ngIf="currencyForm.get('minimumSaleAmount')?.errors?.['min']">
                El valor debe ser mayor o igual a {{paramLowMinimumSaleAmount}}
              </span>
              <span *ngIf="currencyForm.get('minimumSaleAmount')?.errors?.['max']">
                El valor debe ser menor o igual a {{paramMaxMinimumSaleAmount}}
              </span>
              <span *ngIf="currencyForm.errors?.['minimumSaleAmountLessThanmaximumSaleAmount']">
                Debe ser menor que el monto máximo de envío.
              </span>
            </bc-form-field>
            <bc-form-field>
              <em>currency-peso</em>
              <input id="5" bc-input  type="number" [inputValue]="true" value="" formControlName="maximumSaleAmount" required>
              <label for="5">Monto máximo envío:</label>
              <span *ngIf="currencyForm.get('maximumSaleAmount')?.errors?.['min']">
                El valor debe ser mayor o igual a {{paramLowMaximumSaleAmount}}
              </span>
              <span *ngIf="currencyForm.get('maximumSaleAmount')?.errors?.['max']">
                El valor debe ser menor o igual a {{paramMaxMaximumSaleAmount}}
              </span>
            </bc-form-field>
            
          </div>

          <div class="bc-col-6 text-center bc-mt-3 bc-mb-3">
            <bc-form-field>
              <em>currency-peso</em>
              <input id="6" bc-input  type="number" [inputValue]="true" value="" formControlName="minimumPurchaseAmount" required>
              <label for="6">Monto mínimo recepción:</label>
              <span *ngIf="currencyForm.get('minimumPurchaseAmount')?.errors?.['min']">
                El valor debe ser mayor o igual a {{paramLowMinimumPurchaseAmount}}
              </span>
              <span *ngIf="currencyForm.get('minimumPurchaseAmount')?.errors?.['max']">
                El valor debe ser menor o igual a {{paramMaxMinimumPurchaseAmount}}
              </span>
              <span *ngIf="currencyForm.errors?.['minimumPurchaseAmountLessThanmaximumPurchaseAmount']">
                Debe ser menor que el monto máximo de recepción.
              </span>
            </bc-form-field>
            <bc-form-field>
              <em>currency-peso</em>
              <input id="7" bc-input  type="number" [inputValue]="true" value="" formControlName="maximumPurchaseAmount" required>
              <label for="7">Monto máximo recepción:</label>
              <span *ngIf="currencyForm.get('maximumPurchaseAmount')?.errors?.['min']">
                El valor debe ser mayor o igual a {{paramLowMaximumPurchaseAmount}}
              </span>
              <span *ngIf="currencyForm.get('maximumPurchaseAmount')?.errors?.['max']">
                El valor debe ser menor o igual a {{paramMaxMaximumPurchaseAmount}}
              </span>
            </bc-form-field>
            
          </div>

          <div class="bc-col-6 marg-top-20 text-center" >
            <label for="validatePaymentOrder">¿Validar orden de pago?</label>
            <bc-icon class="bc-icon bc-mx-2" size="2xs" bc-tooltip bcTooltipPosition="right" 
            [bcTooltipText]="yesNoPaymentOrderText">info-circle</bc-icon>
            <bc-input-select [values]="siNo" [status]="validatePaymentOrderStatus" formControlName="validatePaymentOrder" id="validatePaymentOrder" ></bc-input-select>
          </div>
          
          <div class="bc-col-6 marg-top-20 text-center">
            <label for="validateAccountBalance">¿Validar saldo en cuenta?</label>
            <bc-icon class="bc-icon bc-mx-2" size="2xs" bc-tooltip bcTooltipPosition="right" 
            [bcTooltipText]="yesNoAccountBalanceText">info-circle</bc-icon>
            <bc-input-select id="validateAccountBalance" [status]="validateAccountBalanceStatus" [values]="siNo" formControlName="validateAccountBalance" ></bc-input-select>
          </div>
          
          <div *ngIf="false" class="bc-col-6 marg-top-20 text-center">
            <label>Estado:</label>
            <bc-input-select [values]="habDesh" status="error" formControlName="status" ></bc-input-select>
          </div>
        </div>
      </form>
    </div>

  </div>
</bc-modal>