import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SPREAD_TASA_EFECTIVO } from '@app/core/constants/routes';
import { SpreadTasasComponent } from './spread-tasas/spread-tasas.component';
import { RoleGuard } from '@app/core/guards/roles/role.guard';

const routes: Routes = [
  {
    path: SPREAD_TASA_EFECTIVO,
    component: SpreadTasasComponent,
    canActivate: [RoleGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TasasRoutingModule { }
