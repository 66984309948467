import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';
import { ISelectValues } from '../../../../src/app/core/models/informacion-parametrica';
import { ParameterizedSegmentSummary } from '../../../../src/app/core/models/segment-mpt';
import { AlertService } from '@app/core/services/alert.service';
import { AlertType } from '@app/core/models/alert-params';
import { SegmentMptApiService } from '@app/core/services/segment-mpt-api.service';
import { RolService } from '@app/core/services/rol.service';
import { Router } from '@angular/router';
import { minLessThanMaxValidator } from '@app/core/validators/custom-validators';

@Component({
  selector: 'app-vista-parametros-clientes',
  templateUrl: './vista-parametros-segmentos.component.html',
  styleUrls: ['./vista-parametros-segmentos.component.scss'],
})
export class VistaParametrosSegmentosComponent implements OnInit {
  action = false;
  typeIcon = 'error';
  closeModal = false;
  customWidth = 'small';
  titleAlign = '';
  buttonAlignment = 'horizontal';
  selectedValue = '';
  subTitle = '';
  loading = false;
  segmentFound= true;
  paramLowMinimumSaleAmount = 1;
  paramMaxMinimumSaleAmount = 1000000;
  paramLowMaximumSaleAmount = 1;
  paramMaxMaximumSaleAmount = 1000000;
  paramLowMinimumPurchaseAmount = 1;
  paramMaxMinimumPurchaseAmount = 1000000;
  paramLowMaximumPurchaseAmount = 1;
  paramMaxMaximumPurchaseAmount = 1000000;

  validatePaymentOrderStatus= 'enabled' as 'error' | 'disabled' | 'enabled';
  validateAccountBalanceStatus = 'enabled' as 'error' | 'disabled' | 'enabled';

  yesNoPaymentOrderText: string = `Si: Con esta opción el cliente podrá pactar una tasa solo si tiene ordenes de pago disponibles
    \n No: Con esta opción el cliente podrá pactar una tasa sin tener ordenes de pago disponibles.`;
  yesNoAccountBalanceText: string = `Si: Con esta opción el cliente podrá pactar una tasa solo si tiene saldo disponible en cuenta.
  \n No: Con esta opción el cliente podrá pactar una tasa sin tener saldo disponible en cuenta.`;
  modalTittle: string = 'Parametrización restricciones segmento';
  @ViewChild('segmentParam', { static: false })
  segmentParam!: BcModalComponent;

  subsegmentList!: ISelectValues[];
  subsegmentParams!: ParameterizedSegmentSummary[];
  subsegmentParamsAux!: ParameterizedSegmentSummary[];
  currentSegment!: ParameterizedSegmentSummary;
  subsegmentsParamsForm: FormGroup;
  searchSubsegment: FormGroup;
  docTypes: ISelectValues[] = [
    { label: 'CC', value: 1 },
    { label: 'NIT', value: 2 },
  ];

  divisas: ISelectValues[] = [
    { label: 'USD', value: 1 },
    { label: 'COP', value: 2 },
  ];

  siNo: ISelectValues[] = [
    { label: 'Si', value: true },
    { label: 'No', value: false },
  ];
  editPrivileges = false;
  habDesh: ISelectValues[] = [
    { label: 'Habilitado', value: 1 },
    { label: 'Deshabilitado', value: 2 },
  ];
  constructor(
    private segmentMptService: SegmentMptApiService,
    private fb: FormBuilder,
    private alertService: AlertService,
    private roleService: RolService,
    private router:Router,
  ) {
    this.searchSubsegment = this.fb.group({ 
      selector: [''] 
    });
    this.subsegmentsParamsForm = this.fb.group({
      codSegmento: ['', [Validators.required]],
      subsegment: ['', []],
      currency: ['USD', [Validators.required, Validators.min(0)]],
      minimumSaleAmount: [
        '',
        [Validators.required, Validators.min(this.paramLowMinimumSaleAmount), Validators.max(this.paramMaxMinimumSaleAmount)],
      ],
      maximumSaleAmount: [
        '',
        [Validators.required, Validators.min(this.paramLowMaximumSaleAmount), Validators.max(this.paramMaxMaximumSaleAmount)],
      ],
      minimumPurchaseAmount: [
        '',
        [Validators.required, Validators.min(this.paramLowMinimumPurchaseAmount), Validators.max(this.paramMaxMinimumPurchaseAmount)],
      ],
      maximumPurchaseAmount: [
        '',
        [Validators.required, Validators.min(this.paramLowMaximumPurchaseAmount), Validators.max(this.paramMaxMaximumPurchaseAmount)],
      ],
      validatePaymentOrder: ['Si', [Validators.required]],
      validateAccountBalance: ['Si', [Validators.required]],
      maximoOperaciones: [],
      status: ['Habilitado', []],
    }, { validators: [minLessThanMaxValidator('minimumSaleAmount', 'maximumSaleAmount'), minLessThanMaxValidator('minimumPurchaseAmount', 'maximumPurchaseAmount')] });
  }

  values = [];
  selectorFilter = new UntypedFormControl();

  ngOnInit(): void {
    
    this.loading = true;
    this.editPrivileges = this.roleService.enablePrivileges((this.router.url.split('/')[2]));
    
    this.segmentMptService.getSegmentListMdm().subscribe({
      next: (data: any) => {
        this.subsegmentList = data.map((x: any) => {
          return {  
            label: `${x.nombreSegmento} ${x.nombreSubsegmento}`,
            value: x.codSegSubseg,
            other: {
              codSubsegmento: x.codSubsegmento,
              codSegmento: x.codSegmento,
            },
          };
        });         
      },
      error: (err: any) => {
        console.log('error:', err);
        this.alertService.showAlert(
          AlertType.Error,
          'Error al cargar los segmentos.'+err
        );
      },
      complete: () => {
        this.loading = false;
      }
    });
      
    this.segmentMptService.getSubSegmentsParams().subscribe({
      next: (data: ParameterizedSegmentSummary[]) => {
        this.subsegmentParams = data.map((x: any) => {
          return { ...x, 
            codSegmento: x.codSegmento,
            subsegment: x.segmento,
           };
        });
        this.subsegmentParamsAux = this.subsegmentParams;        
      },
      error: (err: any) => {
        console.log('error:', err);
        this.alertService.showAlert(
          AlertType.Error,
          'Error al cargar los segmentos.'+err
        );
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
  loadSubSegments(){
    this.loading = true;
    this.segmentMptService.getSubSegmentsParams().subscribe({
      next: (data: ParameterizedSegmentSummary[]) => {
        this.subsegmentParams = data.map((x: any) => {
          return { ...x, 
            codSegmento: x.codSegmento,
            subsegment: x.segmento,
           };
        });
        this.subsegmentParamsAux = this.subsegmentParams;        
      },
      error: (err: any) => {
        console.log('error:', err);
        this.alertService.showAlert(
          AlertType.Error,
          'Error al cargar los segmentos.'+err
        );
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
  showSelectedValue(value: any) {
    this.subsegmentParams = this.subsegmentParamsAux.filter(
      (x) => parseInt(x.codSegmento) == value
    ) || [];
    if (this.subsegmentParams.length == 0) 
      this.segmentFound = false;
    else if (this.subsegmentParams.length > 0)
      this.segmentFound = true;
  }
  status: any;

  clearClientSearch() {
    
    this.searchSubsegment.patchValue({
      selector: ''
    });
    this.searchSubsegment.reset();
    this.searchSubsegment.markAsPristine();
    this.searchSubsegment.markAsUntouched();
    this.subsegmentParams = this.subsegmentParamsAux;
    this.segmentFound = true;
  }

  onModalPressButton(event:any) {
    switch (event) {
      //cancelar
      case 0:
        this.segmentParam.shutDown(); 
        
        break;
      //confirmar
      case 1:
          this.onSubmit();
      break;
    
      default:
        break;
    } 
  }
  openModal(subSegment: ParameterizedSegmentSummary | string) {
    let code: string | undefined;
    let segment: ParameterizedSegmentSummary | undefined;

    if (typeof subSegment === 'string') {
      code = subSegment;
    } else {
      segment = subSegment;
    }
    this.currentSegment = segment || { codSegmento: code } as unknown as ParameterizedSegmentSummary;
    this.subsegmentsParamsForm.reset();
  
    this.subsegmentsParamsForm.patchValue({
      codSegmento: code ?? segment?.codSegmento,
      subsegment: code ? this.subsegmentList.find((x) => x.value.toString() === code)?.label : segment?.subsegment,
      currency: this.divisas.find((x) => x.label == 'USD')?.value,
      maximumPurchaseAmount: segment?.montoMaxRecepcion || 0,
      maximumSaleAmount: segment?.montoMaxEnvio || 0,
      minimumPurchaseAmount: segment?.montoMinRecepcion || 0,
      minimumSaleAmount: segment?.montoMinEnvio || 0,
      validatePaymentOrder: segment?.ordenPago || false,
      validateAccountBalance: segment?.saldoCuenta || false,
      maximoOperaciones: segment?.maximoOperaciones || null,
      status: segment?.status?.value,
    });
    if(!this.editPrivileges){
      this.validatePaymentOrderStatus = 'disabled';
      this.validateAccountBalanceStatus = 'disabled';
    }
    
    this.segmentParam.openModal();
    this.subsegmentsParamsForm.markAsPristine();
  }


  onSubmit() {
    const formValues = this.subsegmentsParamsForm.value;
    
    if (this.subsegmentsParamsForm.valid && this.subsegmentsParamsForm.dirty) {
      this.loading = true;
      const subsegmentRestrictionsToSave: ParameterizedSegmentSummary = {
        codSegmento: formValues.codSegmento,
        segmento: null,
        moneda: this.divisas.find((x) => x.value == formValues.currency)?.label || 'USD',
        montoMaxEnvio: formValues.maximumSaleAmount,
        montoMaxRecepcion: formValues.maximumPurchaseAmount,
        montoMinEnvio: formValues.minimumSaleAmount,
        montoMinRecepcion: formValues.minimumPurchaseAmount,
        ordenPago: formValues.validatePaymentOrder,
        saldoCuenta: formValues.validateAccountBalance,
        maximoOperaciones: formValues.maximoOperaciones,
        status: formValues.status,
      };
      this.segmentMptService.saveSubSegmentParameters(subsegmentRestrictionsToSave).subscribe({
        next: (data: any) => {
          if (data.procesado) 
            this.alertService.showAlert(AlertType.Success, 'Datos guardados correctamente');
          this.loadSubSegments();
        },
        error: (err: any) => {
          console.log('error:', err);
          this.alertService.showAlert(AlertType.Error, 'Error al guardar los datos. '+err);
        },
        complete: () => {
          this.loadSubSegments();
          this.loading = false;
          this.segmentParam.shutDown(); 
          this.clearClientSearch();
        },
      });
    }
  }

}
