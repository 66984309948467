import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SistemaService {
  apiUrl = 'https://negocios-inversion-int-dev.apps.ambientesbc.com/mpt/sistema-mpt';
  readonly headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Accept', 'application/json')
  .set('Message-id', '123456');
  readonly options = { headers: this.headers };
  constructor(private http: HttpClient) {}

  postDiaHabil(): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/diaHabil`,{}, this.options);
  }

  postHorarioHabil(): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/horarioHabil`,{}, this.options);
  }

  postUltimoEstado(): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/validarUltimoEstado`,{}, this.options);
  }
  
}
