import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OperationResume } from '../models/operaciones-mx';

@Injectable({
  providedIn: 'root'
})
export class OpsMurexApiService {
  private options = { headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=UTF-8',
    'Message-Id': '1234567890',
  }) };
  apiUrl = 'https://negocios-inversion-int-dev.apps.ambientesbc.com/mpt/ms-registro-mpt/front';
  
  constructor(private http: HttpClient) {}

  postAllOperations(page: number = 1, size: number = 5): Observable<OperationResume[]> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());

    return this.http.post<OperationResume[]>(`${this.apiUrl}/listarOperaciones`, {
      "fechaConsulta" : '2025-03-03',
      "trader" : null,
      "identificacionCliente" : null
    }, {
      ...this.options,
      params
    });
  }
}
