import { Component, OnInit, ViewChild } from '@angular/core';
import { OperationResume } from '../../../../src/app/core/models/operaciones-mx';
import { OpsMurexApiService } from '../../../../src/app/core/services/ops-murex-api.service';
import { BcDataTableConfig } from '@bancolombia/design-system-behaviors';
import { BcDataTableThCell } from '@bancolombia/design-system-behaviors/lib/behaviors/organisms/bc-data-table/bc-data-table-config-interface';
import { BcDataTableComponent } from '@bancolombia/design-system-web/bc-data-table';

@Component({
  selector: 'app-vista-operaciones',
  templateUrl: './vista-operaciones.component.html',
  styleUrls: ['./vista-operaciones.component.scss']
})
export class VistaOperacionesComponent implements OnInit{
  @ViewChild('bcDataTable', { static: false })
  bcDataTable!: BcDataTableComponent;
  operationsResume!: OperationResume[];
  resp ={
  totalRegistros: 1,
  dataList: {
    tipoNeg: "S",
    tasaNeg: 4238.7,
    idOperacion: "65345720250228",
    contractNumber: "null",
    fechaNeg: "2025-02-28",
    horaNeg: "09:15:19",
    montoNeg: 12200.0,
    totalPagoMdaCop: 7.4940216E7,
    tipoCliente: "REAL",
    nroDocum: "713081688599246",
    monedaFuerte: "USD",
    monedaDebil: "COP",
    tipologia: "FX SPOT T+0",
    estadoMurex: "PENDIENTE",
    estadoCib: "PENDIENTE",
    estadoSetfx: "PENDIENTE",
    }
  }
  testDataFull: typeof this.resp.dataList[] = [];
 
  testColumnHeaders: BcDataTableThCell[] = [
    {
      name: 'Id Op.',
      dataType: 'string',
      tooltip: 'Identificador de la operación',
    },
    {
      
      name: 'Número contrato',
      dataType: 'string',
      tooltip: 'Número de contrato de la operación',
    },
    {
      name: 'Tasa Negociada',
      dataType: 'string',
      tooltip: 'Tasa por unidad de moneda negociada',
    },
    {
      name: 'Monto negociado',
      dataType: 'string',
      tooltip: 'Monto total negociado',
    },
    {
      name: 'Fecha negociación',
      dataType: 'string',
      tooltip: 'Fecha y hora de la negociación',
    },
    {
      name: 'Conversión',
      dataType: 'string',
      tooltip: 'Sentido de conversión realizada en la operación',
    },
    {
      name: 'Estado Murex',
      dataType: 'string',
      tooltip: 'Estado de la operación en Murex',
    },
    {
      name: 'Estado CIB',
      dataType: 'string',
      tooltip: 'Estado de la operación en CIB',
    },
    {
      name: 'Estado SetFx',
      dataType: 'string',
      tooltip: 'Estado de la operación en SetFx',
    },
  ];
  dtConfigTableFull:BcDataTableConfig = {
    id: 'data-table-full',
    data: this.testDataFull,
    multicheck: false,
    columnHeaders: {
      data: this.testColumnHeaders,
      sort: true,
      separation: 'm',
    },
    actions: {
    },
    header: {
      title: {
        text: 'Asignar mesa a trader',
        icon: 'direct-user',
      }
    },
    
  };

  constructor(private opsMurexApiService: OpsMurexApiService){

  }

  ngOnInit(): void {
    this.opsMurexApiService.postAllOperations().subscribe({
      next: (data: any) =>{
        this.operationsResume = data;
        this.updateDataTable(data?.dataList);
      },
      error: (err) => {
        
      },
    })
  }

  updateDataTable(data: any[]){
    this.dtConfigTableFull.data = data.map((op: any) => [
      {
        type: 'string',
        value: op.idOperacion,
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: op.contractNumber !== 'null' ? op.contractNumber : '',
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: op.tasaNeg,
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: op.montoNeg,
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: op.fechaNeg + '.  '+ op.horaNeg,
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: op.monedaFuerte + ' - ' + op.monedaDebil,
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: op.estadoMurex,
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: op.estadoCib,
        class: 'svn-bold-urgent',
      },
      {
        type: 'string',
        value: op.estadoSetfx,
        class: 'svn-bold-urgent',
      },
    ]);
    this.bcDataTable.bcDataTableBehavior.setDataTable(
      this.dtConfigTableFull
    );
  }
}
