<div class="bc-padding-left-4 bc-padding-right-4">
  <div
    class="bc-flex bc-justify-content-center bc-margin-bottom-5 bc-margin-top-5"
  >
    <h2>Spread Tasas Efectivo</h2>
  </div>
  <div bc-card>
    <div class="bc-card-body">
      <h6 class="bc-card-title bc-align-left">
        Tasa trader 8 AM: {{ 4000 | currency }}
      </h6>
      <!-- <p class="bc-card-subtitle bc-align-left">Ahorros</p>
            <p class="bc-card-subtitle-number bc-align-left">020-342566-23</p> -->
      <!-- <bc-data-table [data-table-config]="dtConfigTableFull"></bc-data-table> -->

      <div class="bc-container">
        <div class="bc-row">
          <div class="bc-col-1 bc-text-center bc-d-flex bc-justify-content-around">
            <!-- <div>
                        <span>tasa</span>
                    </div> -->
          </div>
          <div
            class="bc-col-5 bc-text-center bc-d-flex bc-justify-content-around"
          >
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <h6>Tasa base</h6>
            </div>
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <h6>OPERADOR COMPRA (+/-)</h6>
            </div>
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <h6>U*Spread compra</h6>
            </div>
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <h6>Spread compra</h6>
            </div>
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <h6>Tasa compra</h6>
            </div>
          </div>
          <div
            class="bc-col-4 bc-text-center bc-d-flex bc-justify-content-around"
          >
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <h6>OPERADOR VENTA (+/-)</h6>
            </div>
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <h6>U*Spread Venta</h6>
            </div>
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <h6>Spread Venta</h6>
            </div>
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
                <h6>Tasa Venta</h6>
            </div>
          </div>
        </div>
        <div class="bc-row">
          <div class="bc-col-1 bc-text-center bc-d-flex bc-justify-content-around">
            <div>
                <span>Plena</span>
            </div>
          </div>
          <div class="bc-col-5 bc-text-center bc-d-flex bc-justify-content-around">
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <span>Fix</span>
            </div>
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <span>-</span>
            </div>
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <span>Unidad</span>
            </div>
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <span>{{15 | currency}} </span>
            </div>
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <span>{{3985 | currency}} </span>
            </div>
          </div>
          <div class="bc-col-4 bc-text-center bc-d-flex bc-justify-content-around">
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <span>+</span>
            </div>
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <span>Unidad</span>
            </div>
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <span>{{20 | currency}} </span>
            </div>
            <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
            <span>{{4020 | currency}} </span>
            </div>
          </div>
        </div>
        <div class="bc-row">
            <div class="bc-col-1 bc-text-center bc-d-flex bc-justify-content-around">
              <div>
                  <span>Efectivo</span>
              </div>
            </div>
            <div class="bc-col-5 bc-text-center bc-d-flex bc-justify-content-around">
              <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
                <span>Tasa Plena</span>
              </div>
              <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
                <span>-</span>
              </div>
              <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
                <span>Porcentual</span>
              </div>
              <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
                <span>{{0.15 | percent}} </span>
              </div>
              <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
                <span>{{3985 | currency}} </span>
              </div>
            </div>
            <div class="bc-col-4 bc-text-center bc-d-flex bc-justify-content-around">
              <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
                <span>+</span>
              </div>
              <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
                <span>Porcentual</span>
              </div>
              <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
                <span>{{0.10 | percent}} </span>
              </div>
              <div class="bc-col-1 bc-justify-content-center bc-d-flex bc-align-content-center">
              <span>{{4400 | currency}} </span>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
