import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParameterizedClientSummary } from '../models/client-mpt';
import { environment } from '../../../../src/environments/environment';
import { IDeleteObject } from '../models/informacion-parametrica';

@Injectable({
  providedIn: 'root'
})
export class ClientMptApiService {
  private apiUrl = environment.mptBaseUrl;
  readonly headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Accept', 'application/json')
  .set('Message-id', '123456');
  readonly options = { headers: this.headers };

  constructor(private http: HttpClient) {}
  
  getRetrieveBasicInformation(): Observable<ParameterizedClientSummary[]> {
    return this.http.get<ParameterizedClientSummary[]>(`${this.apiUrl}mpt/clientes-mpt/adminCliente/consultarTodasRestriccionesXCliente`, this.options);

  }

  getRetrieveBlockedClientsInformation(): Observable<any> {
    return this.http.get<any>(`https://run.mocky.io/v3/818829e1-7074-4f25-9a48-a179a1100cc0`);
  }

  getClientByDocumentTypeAndDocumentNumber(documentType: string, documentNumber: string): Observable<ParameterizedClientSummary> {
    
    let params = new HttpParams()
    .set('type', documentType)
    .set('number', documentNumber);
    //TIPDOC_003
    return this.http.get<ParameterizedClientSummary>(`${this.apiUrl}mpt/clientes-mpt/adminCliente/consultarRestriccionesXCliente`, { headers: this.headers, params: params });
  }

  postSaveClientRestrictions(client: ParameterizedClientSummary): Observable<any> { 
    return this.http.post<any>(`${this.apiUrl}mpt/clientes-mpt/adminCliente/guardarRestriccionesXCliente`, client, this.options);
  }

  postDeleteClientRestrictions(client: IDeleteObject): Observable<Boolean> { 
    return this.http.post<Boolean>(`${this.apiUrl}mpt/clientes-mpt/adminCliente/eliminarRestriccionXCliente`, client, this.options);
  }
}
