import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { HomeComponent } from './home/home.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: 'home',
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'clientes',
    loadChildren: () =>
      import('./clientes/clientes.module').then((m) => m.ClientesModule),
  },
  {
    path: 'segmentos',
    loadChildren: () =>
      import('./segmentos/segmentos.module').then((m) => m.SegmentosModule),
  },
  {
    path: 'operaciones',
    loadChildren: () =>
      import('./operaciones/operaciones.module').then(
        (m) => m.OperacionesModule
      ),
  },
  {
    path: 'sistema',
    loadChildren: () =>
      import('./sistema/sistema.module').then((m) => m.SistemaModule),
  },
  {
    path: 'tasas',
    loadChildren: () =>
      import('./tasas/tasas.module').then((m) => m.TasasModule),
  },
  {
    path: 'auth',
    component: MsalRedirectComponent,
    /* canActivate: [MsalGuard] */
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];
const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
